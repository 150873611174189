import { captureException } from '@sentry/react';
import axios from 'axios';
import type { PaginatedList, Track } from 'core';
import type { FC } from 'react';
import { useNavigate } from 'react-router-dom';
import usePromise from 'react-use-promise';
import uri from 'uri-tag';
import { ErrorPage } from '../error/page.js';
import { FeedPageView } from './view.js';

const Controller: FC = () => {
  const navigate = useNavigate();

  const [tracks, error] = usePromise(async () => {
    const { data } = await axios.get<PaginatedList<Track.Type>>(
      '/api/v1/tracks',
      {
        params: {
          sort: '-finish.when',
        },
      },
    );
    return data;
  }, []);

  if (error) return ErrorPage({ error });

  return FeedPageView({
    loading: !tracks,
    onCreateSuccess: (track) => {
      navigate(uri`/track/${track._id}`);
    },
    onReprocessClicked: (track) => {
      axios
        .post(`/api/v1/track/${track._id}/reprocess`)
        .catch(captureException);
    },
    onShowUploadClick: () => navigate('/track/upload'),
    onStravaImportClick: () => navigate('/strava/import'),
    tracks,
  });
};

export default Controller;
