import { Box, Button, Container, Stack } from '@mui/material';
import type { PaginatedList, Track } from 'core';
import type { FC } from 'react';
import { PageTitle } from '../../components/page-title/page-title.js';
import { FeedWidget } from '../../widgets/index.js';

interface Props {
  loading: boolean;
  onCreateSuccess: (track: Track.Type) => void;
  onReprocessClicked: (track: Track.Type) => void;
  onShowUploadClick: () => void;
  onStravaImportClick: () => void;
  tracks?: PaginatedList<Track.Type>;
}

export const FeedPageView: FC<Props> = ({
  loading,
  onCreateSuccess,
  onShowUploadClick,
  onStravaImportClick,
  tracks,
}) => (
  <PageTitle title="Feed">
    <Container maxWidth="md">
      <Stack spacing={3} my={3}>
        <Box display="flex" flex={1} flexDirection="column">
          <Stack spacing={3} direction="row">
            <Button variant="outlined" fullWidth onClick={onShowUploadClick}>
              Upload Track File
            </Button>
            <Button variant="outlined" fullWidth onClick={onStravaImportClick}>
              Import from Strava
            </Button>
          </Stack>
        </Box>

        <FeedWidget loading={loading} tracks={tracks} />
      </Stack>
    </Container>
  </PageTitle>
);
